import { Component, OnInit, Input } from '@angular/core';
import { Article } from '../../model/article.model';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-article-item',
  templateUrl: './article-item.component.html',
  styleUrls: ['./article-item.component.css']
})
export class ArticleItemComponent implements OnInit {
  @Input() article: Article;
  @Input() index: number;

  constructor(
    private router: Router,
    public authService: AuthService
  ) { }

  ngOnInit() {
  }

  updateArticle(article: Article) {
    this.router.navigate(['articlesEdit', article.id]);
  }

  deleteArticle(article: Article) {
    this.router.navigate(['articlesDelete', article.id]);
  }
}
