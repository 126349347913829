import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { AppRoutingModule } from '../app-routing.module';
import { HeaderService } from '../services/header.service';
import { ArticlesService } from '../services/articles.service';
import { AuthService } from '../services/auth.service';
import { DatePipe } from '@angular/common'
import { AuthGuardService } from '../services/auth-guard.service';
import { ContactsService } from '../services/contacts.service';
import { ContactsModule } from '../contacts/contacts.module';

@NgModule({
  declarations: [
    HeaderComponent,
    HomeComponent
  ],
  exports: [
    AppRoutingModule,
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    ContactsModule
  ],
  providers: [
    HeaderService,
    ArticlesService,
    AuthService,
    AuthGuardService,
    ContactsService,
    DatePipe
  ]
})
export class CoreModule { }
