import { Component, OnInit, Input } from '@angular/core';
import { Contact } from '../../model/contact.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contacts-list-item',
  templateUrl: './contacts-list-item.component.html',
  styleUrls: ['./contacts-list-item.component.css']
})
export class ContactsListItemComponent implements OnInit {
  @Input() contact: Contact;
  
  constructor(private router : Router) { }

  ngOnInit() {
  }

  viewContactDetails(id : string){
    this.router.navigate(['contactsDetails', id]);
  }

  updateConact(conactId : string){
    this.router.navigate(['menageContacts', conactId]);
  }

  deleteConact(conactId : string){
    this.router.navigate(['deleteContacts', conactId , 'true']);
  }
}
