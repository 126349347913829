import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Contact } from '../model/contact.model';

@Injectable({
  providedIn: 'root'
})
export class MailService {
  private endpoint = `https://us-central1-martacorbettapsicologa.cloudfunctions.net/httpEmail`
  public adminEmail = "martacorby@gmail.com";
  
  constructor(private http: HttpClient) { }

  sendEmailToAdmin(adminEmail: string, contact: Contact) {
    const data = {
      dest: adminEmail,
      contactName: contact.name,
      contactSurname: contact.surname,
      contactEmail: contact.email,
      contactTelephone: contact.telephone,
      contactMessage: contact.notes
    }

    this.http.post(this.endpoint, data).subscribe(
      resp => console.log(resp),
      err => console.log(err)
    )
  }
}