import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './core/home/home.component';
import { ArticlesComponent } from './articles/articles.component';
import { ArticlesCreateComponent } from './articles/articles-create/articles-create.component';
import { LoginComponent } from './auth/login/login.component';
import { SignupComponent } from './auth/signup/signup.component';
import { ArticlesEditComponent } from './articles/articles-edit/articles-edit.component';
import { ArticlesDeleteComponent } from './articles/articles-delete/articles-delete.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ContactsListComponent } from './contacts/contacts-list/contacts-list.component';
import { ContactsDetailItemComponent } from './contacts/contacts-detail-item/contacts-detail-item.component';
import { ContactsEditComponent } from './contacts/contacts-edit/contacts-edit.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'articles', component: ArticlesComponent },
  { path: 'articlesEdit/:id', component: ArticlesEditComponent, canActivate: [AuthGuardService] },
  { path: 'articlesDelete/:id', component: ArticlesDeleteComponent, canActivate: [AuthGuardService] },
  { path: 'createArticle', component: ArticlesCreateComponent, canActivate: [AuthGuardService] },
  { path: 'contacts', component: ContactsListComponent },
  { path: 'contactsDetails/:id', component: ContactsDetailItemComponent },
  { path: 'deleteContacts/:id/:isDelete', component: ContactsDetailItemComponent},
  { path: 'menageContacts', component: ContactsEditComponent},
  { path: 'menageContacts/:id', component: ContactsEditComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent, canActivate: [AuthGuardService] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
