import { Component, OnInit } from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loginResultMessage: string;
  isLoginValid: boolean;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  onLogin() {
    const email = this.loginForm.value['email'];;
    const password = this.loginForm.value['password'];
    this.authService.signinUser(email, password).then(
      res => {
        this.isLoginValid = true;
        this.loginResultMessage = "Login Effettuato con successo.";
      }, error => {
        this.isLoginValid = false;
        this.loginResultMessage = "C'è stato un errore nel login. Verifica che username (email) e password siano validi.";
      });
  }

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }
}
