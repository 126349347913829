import { Component, OnInit, Input } from '@angular/core';
import { ArticlesService } from '../../services/articles.service';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Article } from '../../model/article.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-articles-delete',
  templateUrl: './articles-delete.component.html',
  styleUrls: ['./articles-delete.component.css']
})
export class ArticlesDeleteComponent implements OnInit {

  private sub: any;

  currentEditedArticleId: string;
  @Input() currentArticle: Article;
  deleteArticleResult: string;
  lastModificationDate: string;
  deleteArticleFragmentPath: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private articlesService: ArticlesService,
    private datepipe: DatePipe) {
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(
      (params: Params) => {
        this.currentEditedArticleId = params['id'];
        this.articlesService.getArticleFromServerById(this.currentEditedArticleId).then(res => {
          if (res.exists) {
            console.log("Document data:", res.data());
            let foundArticle = res.data() as Article;
            this.currentArticle = foundArticle;
            this.lastModificationDate = this.datepipe.transform(this.currentArticle.lastModificationDate.toDate(), 'dd-MM-yyyy - HH:mm:ss');
          } else {
            this.deleteArticleResult = "Articolo non trovato!";
          }
        }).catch(function (error) {
          console.log("Error getting document:", error);
          this.deleteArticleResult = "Errore nel recupero dell'articolo!";
        });
      }
    );
    this.checkUserIsAuthenticated();
    this.deleteArticleFragmentPath = "articlesDelete/" +this.currentEditedArticleId + "#delete-article";    
  }

  deleteArticle() {
    if (this.checkUserIsAuthenticated()) {
      var response = this.articlesService.deleteCurrentArticleFromServer(this.currentEditedArticleId)
        .then(res => {
          this.deleteArticleResult = "Articolo Cancellato Correttamente"
        })
        .catch
        (res => {
          this.deleteArticleResult = "C'è stato un problema con la cancellazione dell'articolo. L'articolo non è stato cancellato!"
        });
    }
    else {
      this.deleteArticleResult = "Per cancellare l'articolo è necessario essere autenticati. Effettua il login."
    }
  }

  checkUserIsAuthenticated(): boolean {
    if (this.authService.isAuthenticated()) {
      return true;
    }
    else {
      this.deleteArticleResult = "Per cancellare l'articolo è necessario essere autenticati. Effettua il login."
      return false;
    }
  }
}
