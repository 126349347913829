import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticlesListComponent } from './articles-list/articles-list.component';
import { ArticlesComponent } from './articles.component';
import { ArticlesEditComponent } from './articles-edit/articles-edit.component';
import { QuillModule } from 'ngx-quill'
import { ArticlesCreateComponent } from './articles-create/articles-create.component';
import { FormsModule } from '@angular/forms';
import { ArticleItemComponent } from './article-item/article-item.component';
import { SafePipe } from '../shared/safePipe';
import { ArticlesDeleteComponent } from './articles-delete/articles-delete.component';

@NgModule({
  declarations: [    
    ArticlesComponent,
    ArticleItemComponent,
    ArticlesListComponent,
    ArticlesEditComponent,
    ArticlesCreateComponent,
    ArticlesDeleteComponent,
    SafePipe
  ],
  imports: [
    CommonModule,
    QuillModule,
    FormsModule
  ],
})
export class ArticlesModule { }
