import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule, OnInit } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ArticlesModule } from './articles/articles.module';
import { AuthModule } from './auth/auth.module';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { ContactsModule } from './contacts/contacts.module';
import { AppModule } from './app.module';

@NgModule({
   imports: [
      BrowserModule.withServerTransition({ appId: "marta-corbetta-psicologa" }),
      HttpClientModule,
      AppRoutingModule,
      CoreModule,
      ArticlesModule,
      ContactsModule,
      AuthModule,
      AngularFireModule.initializeApp(environment.firebaseConfig),
      AngularFirestoreModule,
      AppModule,
      BrowserTransferStateModule
   ],
   providers: [],
   bootstrap: [
      AppComponent
   ]
})
export class AppBrowserModule implements OnInit {
   constructor() { }

   ngOnInit() {
   }
}
