import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Contact } from '../../model/contact.model';
import { ContactsService } from '../../services/contacts.service';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Params } from '@angular/router';
import { MailService } from '../../services/mail.service';

@Component({
  selector: 'app-contacts-edit',
  templateUrl: './contacts-edit.component.html',
  styleUrls: ['./contacts-edit.component.css']
})
export class ContactsEditComponent implements OnInit {

  @Input() componentLocation: string;
  public contactForm: FormGroup;
  public saveContactResult: string;
  @Input() isContactValid: boolean;
  private contactId: string;
  public isEdit: boolean;
  public contactToEdit: Contact;
  private sub: any;
  public submitButtonText: string;
  editContactFragmentPath: string;

  constructor(
    private formBuilder: FormBuilder,
    private contactService: ContactsService,
    public authService: AuthService,
    private route: ActivatedRoute,
    private mailService: MailService) { }

  ngOnInit() {
    this.initForm();
    if (this.componentLocation == 'home') {
      this.submitButtonText = "Invia Messaggio";
    }
    else {
      this.submitButtonText = "Crea Contatto";
    }
    this.sub = this.route.params.subscribe(
      (params: Params) => {
        this.contactId = params['id'];
        if (this.contactId != '' && this.contactId != undefined) {
          this.checkUserIsAuthenticated();
          this.isEdit = true;
          this.submitButtonText = "Edita Contatto";
          this.contactService.getContactFromServerById(this.contactId).then(res => {
            if (res.exists) {
              console.log("Document data:", res.data());
              let foundConact = res.data() as Contact;
              this.contactToEdit = foundConact;
              this.initFormForEdit();
            } else {
              this.saveContactResult = "Contatto non trovato!";
              this.isContactValid = false;
            }
          }).catch(function (error) {
            console.log("Error getting document:", error);
            this.saveContactResult = "Errore nel recupero del contatto!";
            this.isContactValid = false;
          });
        }
      }
    );
  }

  initFormForEdit() {
    this.contactForm.setValue({
      name: this.contactToEdit.name,
      surname: this.contactToEdit.surname,
      email: this.contactToEdit.email,
      telephone: this.contactToEdit.telephone,
      note: this.contactToEdit.notes,
      hasBeenContacted: this.contactToEdit.hasBeenContacted
    });

    this.editContactFragmentPath = "menageContacts/" + this.contactId + "#contact-list-start";
  }

  initForm() {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      surname: [''],
      email: ['', [Validators.required, Validators.email]],
      telephone: [''],
      note: ['', Validators.required],
      hasBeenContacted: [false]
    });

    this.editContactFragmentPath = "menageContacts#contact-list-start";
  }

  onSubmit() {

    var contactName = this.contactForm.value['name'];
    var contactSurname = this.contactForm.value['surname'];
    var contactEmail = this.contactForm.value['email'];
    var contactTelephone = this.contactForm.value['telephone'];
    var contactNote = this.contactForm.value['note'];
    var contactHasBeenContacted = this.contactForm.value['hasBeenContacted'];

    if (!this.isEdit) {
      var contact = new Contact('', contactName, contactSurname, contactEmail, contactTelephone, contactNote, false, new Date());
      this.contactService.saveCurrentContactToServer(contact).then(res => {
        if (this.componentLocation == 'home') {
          this.isContactValid = true;
          this.saveContactResult = "Richiesta ricevuta. Sarà contattato al più presto."
          this.mailService.sendEmailToAdmin(this.mailService.adminEmail, contact);
        }
        else {
          this.saveContactResult = "Contatto inserito correttamente"
          this.isContactValid = true;
        }
      })
        .catch
        (res => {
          this.saveContactResult = "C'è stato un problema con il salvataggio del contatto. Il contatto non è stato salvato!"
          this.isContactValid = false;
        });
    }
    else {
      var updatedContact = new Contact(this.contactId, contactName, contactSurname, contactEmail, contactTelephone, contactNote, contactHasBeenContacted, this.contactToEdit.requestDate);
      this.contactService.updateCurrentContactToServer(this.contactId, updatedContact).then(res => {
        this.saveContactResult = "Contatto aggiornato correttamente";
        this.isContactValid = true;
      })
        .catch
        (res => {
          this.saveContactResult = "C'è stato un problema con l'aggiornamento del contatto. Il contatto non è stato aggiornato!"
          this.isContactValid = false;
        });
    }
  }

  onCancel() {
    this.contactForm.reset();
  }

  get email() {
    return this.contactForm.get('email');
  }

  get name() {
    return this.contactForm.get('name');
  }

  get note() {
    return this.contactForm.get('note');
  }

  checkUserIsAuthenticated(): boolean {
    if (this.authService.isAuthenticated()) {
      return true;
    }
    else {
      this.saveContactResult = "Per aggiungere o modificare il contatto è necessario essere autenticati. Effettua il login."
      this.isContactValid = false;
      return false;
    }
  }

}
