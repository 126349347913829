import { Component, OnInit, Input } from '@angular/core';
import { Contact } from '../../model/contact.model';
import { ContactsService } from '../../services/contacts.service';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-contacts-detail-item',
  templateUrl: './contacts-detail-item.component.html',
  styleUrls: ['./contacts-detail-item.component.css']
})
export class ContactsDetailItemComponent implements OnInit {
  @Input() contact: Contact;
  private currentContactId: string;
  public contactVisitDate: string;
  public foundContactResult: string;
  private sub: any;
  public isDelete: string;
  private canDelete: boolean;
  contactDetailsTitle: string;
  contactDetailsSubtitle: string;
  contactDetailsFragmentPath: string;
  contactDetailsButtonText: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private contactService: ContactsService,
    private datepipe: DatePipe
  ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(
      (params: Params) => {
        this.currentContactId = params['id'];
        this.isDelete = params['isDelete'];
        if (this.isDelete) {
          this.contactDetailsTitle = "Elimina Contatto";
          this.contactDetailsSubtitle = "Gestisci eliminazione Contatto";
          this.contactDetailsFragmentPath = "deleteContacts/" + this.currentContactId  +"/true" + "#contact-list-start";
          this.contactDetailsButtonText = "Gestione Eliminazione";
        }
        else {
          this.contactDetailsTitle = "Dettagli Contatto";
          this.contactDetailsSubtitle = "Visualizzazione dettagli del Contatto";
          this.contactDetailsFragmentPath = "contactsDetails/" + this.currentContactId  +"#contact-list-start";
          this.contactDetailsButtonText = "Visualizza Dettagli";
        }
        this.contactService.getContactFromServerById(this.currentContactId).then(res => {
          if (res.exists) {
            console.log("Document data:", res.data());
            let foundContact = res.data() as Contact;
            this.contact = foundContact;
            this.contactVisitDate = this.datepipe.transform(this.contact.requestDate.toDate(), 'dd-MM-yyyy - HH:mm:ss');
            this.canDelete = this.checkUserIsAuthenticated()
          } else {
            this.foundContactResult = "Contatto non trovato!";
          }
        }).catch(function (error) {
          console.log("Error getting document:", error);
          this.foundContactResult = "Errore nel recupero del contatto!";
        });
      }
    );
  }

  deleteConact() {
    if (this.checkUserIsAuthenticated()) {
      var response = this.contactService.deleteCurrentContactFromServer(this.currentContactId)
        .then(res => {
          this.foundContactResult = "Contatto Cancellato Correttamente"
        })
        .catch
        (res => {
          this.foundContactResult = "C'è stato un problema con la cancellazione del contatto. Il contatto non è stato cancellato!"
        });
    }
    else {
      this.foundContactResult = "Per cancellare il contatto è necessario essere autenticati. Effettua il login."
    }
  }

  checkUserIsAuthenticated(): boolean {
    if (this.authService.isAuthenticated() || (this.isDelete == '' || this.isDelete == undefined)) {
      return true;
    }
    else {
      this.foundContactResult = "Per cancellare il contatto è necessario essere autenticati. Effettua il login."
      return false;
    }
  }

}
