import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactsDetailItemComponent } from './contacts-detail-item/contacts-detail-item.component';
import { ContactsListComponent } from './contacts-list/contacts-list.component';
import { ContactsListItemComponent } from './contacts-list-item/contacts-list-item.component';
import { ContactsEditComponent } from './contacts-edit/contacts-edit.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [
    ContactsListComponent,
    ContactsListItemComponent,
    ContactsDetailItemComponent,
    ContactsEditComponent,
  ],
  exports: [ContactsEditComponent]
})
export class ContactsModule { }
