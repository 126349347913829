import { Injectable } from '@angular/core';
import { Article } from '../model/article.model';
import 'rxjs';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {

  private articles: Observable<Article[]>;
  private articlesCollection: AngularFirestoreCollection<Article>;

  constructor(
    private db: AngularFirestore) { }

  getArticlesFromServer() {
    this.articlesCollection = this.db.collection<Article>('articles');
    this.articles = this.articlesCollection.valueChanges();
    return this.articles;
  }

  getArticleFromServerById(id: string) {
    return new Promise<any>(
      (resolve, reject) => {
        this.articlesCollection = this.db.collection<Article>('articles');
        var result = this.articlesCollection.doc(id).ref.get().then(res => {
          resolve(res);
        }).catch(
          error => {
            console.log(error)
            reject(error);
          });
      });
  }

  saveCurrentArticleToServer(htmlArticleContent: string) {
    let articleId = this.db.createId();
    const article = new Article(articleId, htmlArticleContent, new Date());
    return new Promise<any>(
      (resolve, reject) => {
        this.articlesCollection = this.db.collection<Article>('articles');
        var insertResult = this.articlesCollection.doc(articleId).set(Object.assign({}, article)).then(
          response => {
            var res = response;
            resolve();
          })
          .catch(
            error => {
              console.log(error)
              reject(error);
            }
          )
      });
  }

  updateCurrentArticleToServer(articleId: string, htmlArticleContent: string) {
    return new Promise<any>(
      (resolve, reject) => {
        if (htmlArticleContent == undefined) {
          this.getArticleFromServerById(articleId).then(res => {
            if (res.exists) {
              let foundArticle = res.data() as Article;
              htmlArticleContent = foundArticle.htmlArticle;
              this.articlesCollection = this.db.collection<Article>('articles');
              this.articlesCollection.doc(articleId).update({ htmlArticle: htmlArticleContent, lastModificationDate: new Date() });
              resolve();
            }
          }).catch(
            error => {
              console.log(error)
              reject(error);
            });
        }
        else {
          this.articlesCollection = this.db.collection<Article>('articles');
          this.articlesCollection.doc(articleId).update({ htmlArticle: htmlArticleContent, lastModificationDate: new Date() }).then(res => {
            resolve();
          }).catch(
            error => {
              console.log(error)
              reject(error);
            });
        }
      });
  }

  deleteCurrentArticleFromServer(articleId: string, ) {
    return new Promise<any>(
      (resolve, reject) => {
        this.articlesCollection = this.db.collection<Article>('articles');
        this.articlesCollection.doc(articleId).delete().then(res => {
          resolve();
        }).catch(
          error => {
            console.log(error)
            reject(error);
          });
      });
  }
}
