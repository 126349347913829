import { Component, OnInit, OnDestroy } from '@angular/core';
import { Article } from '../../model/article.model';
import { ArticlesService } from '../../services/articles.service';
import { Subscription, Observable } from 'rxjs';

@Component({
  selector: 'app-articles-list',
  templateUrl: './articles-list.component.html',
  styleUrls: ['./articles-list.component.css']
})
export class ArticlesListComponent implements OnInit, OnDestroy {

  articles: Observable<Article[]>;
  subscription: Subscription;

  constructor(
    private articlesService: ArticlesService) {
     }

  ngOnInit() {
    this.articles = this.articlesService.getArticlesFromServer();
  }

  ngOnDestroy() {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }
}
