import { Component, OnInit } from '@angular/core';

import Quill from 'quill'

import ImageResize from 'quill-image-resize-module'
import { ArticlesService } from '../../services/articles.service';
import { AuthService } from '../../services/auth.service';
Quill.register('modules/imageResize', ImageResize)

const font = Quill.import('formats/font')
// We do not add Aref Ruqaa since it is the default
font.whitelist = ['ubuntu', 'pacifico', 'monospace', 'serif', 'mirza', 'roboto', 'aref', 'arial', 'algerian', 'elephant', 'lobster']
Quill.register(font, true)

@Component({
  selector: 'app-articles-create',
  templateUrl: './articles-create.component.html',
  styleUrls: ['./articles-create.component.css']
})
export class ArticlesCreateComponent implements OnInit {

  htmlFormat: string;
  saveArticleResult: string;
  modules = {};
  toolbarOptions = [
    [{ 'font': ['serif', 'san serif', 'monospace', 'mirza', 'roboto', 'aref', 'times new roman', 'arial'] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'align': [] }],       // toggled buttons
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                       // text direction
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    ['link', 'image', 'video'],                         // link and image, video
    ['clean']                                         // remove formatting button
  ];

  constructor(
    private articlesService: ArticlesService,
    private authService: AuthService) {
    this.modules = {
      imageResize: {},
    }
  }

  ngOnInit() {
    this.checkUserIsAuthenticated();
  }

  saveArticle() {
    if (this.checkUserIsAuthenticated()) {
      this.articlesService.saveCurrentArticleToServer(this.htmlFormat)
        .then(res => {
          this.saveArticleResult = "Articolo Salvato Correttamente"
        })
        .catch
        (res => {
          if (res.message.includes('is longer than 1048487 bytes.')) {
            this.saveArticleResult = "C'è stato un problema con il salvataggio dell'articolo. L'articolo contine un'immagine e dei contenuti superiori al limite" + 
            " consentito di 1048487 bytes. Si prega di ridurre la qualità dell'immagine o dei contenuti e riprovare. L'articolo non è stato salvato!"
          }
          else {
            this.saveArticleResult = "C'è stato un problema con il salvataggio dell'articolo. L'articolo non è stato salvato!"
          }
        });
    }
  }

  checkUserIsAuthenticated(): boolean {
    if (this.authService.isAuthenticated()) {
      return true;
    }
    else {
      this.saveArticleResult = "Per salvare il documento è necessario essere autenticati. Effettua il login."
      return false;
    }
  }
}
