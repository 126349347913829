import { Component, OnInit } from '@angular/core';
// import 'rxjs/add/operator/toPromise';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public sendEmailToMarta: string;
  public callMartaPhone: string;

  constructor() { }

  ngOnInit() {
    this.sendEmailToMarta = "mailto:martacorby@gmail.com";
    this.callMartaPhone = "tel:+39 3381872576";
  }
}
