import { Component, OnInit, Input } from '@angular/core';
import { HeaderService } from '../../services/header.service';
import { AuthService } from '../../services/auth.service';

// import * as jquery from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  @Input() navbarOpen: boolean = false;
  @Input() _isAuthenticated: boolean = false;
  get isAuthenticated(): boolean {
    return this._isAuthenticated;
  }
  set isAuthenticated(isAuthenticatedValue: boolean) {
    this._isAuthenticated = isAuthenticatedValue;
  }

  constructor(
    private headerService: HeaderService,
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.navbarOpen = this.headerService.getNavbarState();
    this.isAuthenticated = this.authService.isAuthenticated();
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
    this.headerService.setNavbarState(this.navbarOpen);
  }

  closeNavbar() {
    this.navbarOpen = false;
    this.headerService.setNavbarState(this.navbarOpen);
  }

  onLogout() {
    this.authService.logout();
  }
}
