import { Component, OnInit, OnDestroy } from '@angular/core';
import { Contact } from '../../model/contact.model';
import { Observable, Subscription } from 'rxjs';
import { ContactsService } from '../../services/contacts.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.css']
})
export class ContactsListComponent implements OnInit, OnDestroy {

  contacts: Observable<Contact[]>;
  subscription: Subscription;

  constructor(
    private contactsService : ContactsService,
    private router : Router
  ) { }

  ngOnInit() {
    this.contacts = this.contactsService.getContactsFromServer();
  }

  ngOnDestroy() {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }

  navigateToNewContact() {
    this.router.navigate(['menageContacts']);
  }

}
