import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  isNavbarOpen: boolean = false;

  constructor() { }

  setNavbarState(state: boolean){
    this.isNavbarOpen = state;
  }

  getNavbarState(){
    return this.isNavbarOpen;
  }
}
