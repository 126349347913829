export class Article {
    public id: string;
    public htmlArticle: string;
    public lastModificationDate: any;

    constructor(id: string, htmlArticle: string, lastModificationDate: any) {
        this.id = id;
        this.htmlArticle = htmlArticle;
        this.lastModificationDate = lastModificationDate;
    }
}