import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { Router } from '@angular/router';
import * as jquery from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token: string;
  lastIsAuthenticatedState: boolean;

  constructor(private router: Router) {
    this.lastIsAuthenticatedState = false;
  }

  signupUser(email: string, password: string) {
    firebase.auth().createUserWithEmailAndPassword(email, password)
      .catch(
        error => console.log(error)
      );
  }

  signinUser(email: string, password: string) {
    return new Promise<any>(
      (resolve, reject) => {
        var result = firebase.auth().signInWithEmailAndPassword(email, password)
          .then(
            response => {
              firebase.auth().currentUser.getIdToken()
                .then(
                  (token: string) => {
                    this.token = token
                    //this.router.navigate(['/'])
                    resolve();
                  }
                );
            }
          )
          .catch(
            error => {
              console.log(error)
              reject(error);
            }
          );
      })
  }

  logout() {
    firebase.auth().signOut();
    this.token = null;
  }

  getToken() {
    firebase.auth().currentUser.getIdToken().then(
      (token: string) => this.token = token
    );
    return this.token;
  }

  isAuthenticated() {
    const isAuthenticated: boolean = this.token != null;
    return isAuthenticated
  }
}
