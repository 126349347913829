import { Injectable } from '@angular/core';
import { Contact } from '../model/contact.model';
import { Observable } from 'rxjs';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  private contacts: Observable<Contact[]>;
  private contactsCollection: AngularFirestoreCollection<Contact>;

  constructor(
    private db: AngularFirestore) { }

    getContactsFromServer() {
      this.contactsCollection = this.db.collection<Contact>('contacts');
      this.contacts = this.contactsCollection.valueChanges();
      return this.contacts;
    }
    
    getContactFromServerById(id: string) {
      return new Promise<any>(
        (resolve, reject) => {
          this.contactsCollection = this.db.collection<Contact>('contacts');
          var result = this.contactsCollection.doc(id).ref.get();
          resolve(result);
        });
    }
  
    saveCurrentContactToServer(newContact: Contact) {
      let customerId = this.db.createId();
      if(newContact.id == ''){
        newContact.id = customerId;
      }
      return new Promise<any>(
        (resolve, reject) => {
          this.contactsCollection = this.db.collection<Contact>('contacts');
          this.contactsCollection.doc(customerId).set(Object.assign({}, newContact))
          resolve();
        });
    }
  
    updateCurrentContactToServer(contactId: string, updateContact: Contact) {
      return new Promise<any>(
        (resolve, reject) => {
          this.contactsCollection = this.db.collection<Contact>('contacts');
          this.contactsCollection.doc(contactId).update({ 
            name: updateContact.name, 
            surname: updateContact.surname, 
            email: updateContact.email, 
            telephone: updateContact.telephone, 
            notes: updateContact.notes, 
            hasBeenContacted: updateContact.hasBeenContacted
          });
          resolve();
        });
    }
  
    deleteCurrentContactFromServer(customerId: string, ) {
      return new Promise<any>(
        (resolve, reject) => {
          this.contactsCollection = this.db.collection<Contact>('contacts');
          this.contactsCollection.doc(customerId).delete();
          resolve();
        });
    }
}
