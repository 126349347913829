import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import Quill from 'quill'
import ImageResize from 'quill-image-resize-module'
import { ActivatedRoute, Params } from '@angular/router';
import { Article } from '../../model/article.model';
import { DatePipe } from '@angular/common';

import { AuthService } from '../../services/auth.service';
import { ArticlesService } from '../../services/articles.service';

Quill.register('modules/imageResize', ImageResize)

const font = Quill.import('formats/font')
font.whitelist = ['ubuntu', 'pacifico', 'monospace', 'serif', 'mirza', 'roboto', 'aref', 'arial', 'algerian', 'elephant', 'lobster']
Quill.register(font, true)

@Component({
  selector: 'app-articles-edit',
  templateUrl: './articles-edit.component.html',
  styleUrls: ['./articles-edit.component.css']
})
export class ArticlesEditComponent implements OnInit, OnDestroy {

  htmlArticleUpdatedValue: string;
  updateArticleResult: string;
  @Input() currentEditedArticle: Article;
  currentEditedArticleId: string;
  lastModificationDate: string;
  editArticleFragmentPath: string;
  modules = {};

  private sub: any;

  constructor(
    private articlesService: ArticlesService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private datepipe: DatePipe) {
    this.modules = {
      imageResize: {},
    }
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(
      (params: Params) => {
        this.currentEditedArticleId = params['id'];
        this.articlesService.getArticleFromServerById(this.currentEditedArticleId).then(res => {
          if (res.exists) {
            console.log("Document data:", res.data());
            let foundArticle = res.data() as Article;
            this.currentEditedArticle = foundArticle;
            this.lastModificationDate = this.datepipe.transform(this.currentEditedArticle.lastModificationDate.toDate(), 'dd-MM-yyyy - HH:mm:ss');
          } else {
            this.updateArticleResult = "Articolo non trovato!";
          }
        }).catch(function (error) {
          console.log("Error getting document:", error);
          this.updateArticleResult = "Errore nel recupero dell'articolo!";
        });
      }
    );
    this.checkUserIsAuthenticated();

    this.editArticleFragmentPath = "articlesEdit/" + this.currentEditedArticleId + "#edit-article";
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  updateArticle() {
    if (this.checkUserIsAuthenticated()) {
      var response = this.articlesService.updateCurrentArticleToServer(this.currentEditedArticleId, this.htmlArticleUpdatedValue)
        .then(res => {
          this.updateArticleResult = "Articolo Salvato Correttamente"
        })
        .catch
        (res => {
          if (res.message.includes('is longer than 1048487 bytes.')) {
            this.updateArticleResult = "C'è stato un problema con il salvataggio dell'articolo. L'articolo contine un'immagine e dei contenuti superiori al limite" + 
            " consentito di 1048487 bytes. Si prega di ridurre la qualità dell'immagine o dei contenuti e riprovare. L'articolo non è stato salvato!"
          }
          else {
            this.updateArticleResult = "C'è stato un problema con il salvataggio dell'articolo. L'articolo non è stato salvato!"
          }
        });
    }
    else {
      this.updateArticleResult = "Per modificare l'articolo è necessario essere autenticati. Effettua il login."
    }
  }

  loadValueChange(newValue: string) {
    this.htmlArticleUpdatedValue = newValue;
  }

  checkUserIsAuthenticated(): boolean {
    if (this.authService.isAuthenticated()) {
      return true;
    }
    else {
      this.updateArticleResult = "Per modificare l'articolo è necessario essere autenticati. Effettua il login."
      return false;
    }
  }
}

