export class Contact {
    public id: string;
    public name: string;
    public surname: string;
    public email: string;
    public telephone: string;
    public notes: string;
    public hasBeenContacted: boolean;
    public requestDate: any;

    constructor(
        id: string,
        name: string,
        surname: string,
        email: string,
        telephone: string,
        notes: string,
        hasBeenContacted: boolean,
        requestDate: any) {
        this.id = id;
        this.name = name;
        this.surname = surname;
        this.email = email;
        this.telephone = telephone;
        this.notes = notes;
        this.hasBeenContacted = hasBeenContacted;
        this.requestDate = requestDate;
    }
}